/* set up padding and margin of some main elements */
.main-wrapper {
    margin-top: var(--len-5);
    margin-bottom: var(--len-5);
  }
  
  .main-wrapper { display: flex; flex-wrap: wrap; }
  .main-wrapper > * { height: fit-content; }
  
  .main { padding: var(--len-4); }
  .side { padding-left: var(--len-4); }
  .main { flex: 0 0 72%; width: 72%; }
  .side { flex: 0 0 28%; width: 28%; }
  
  /* mobile layout: place side to bottom */
  @media (max-width: 991px) {
    .main { padding: var(--len-4); }
    .side { padding-left: 0; padding-top: var(--len-4); }
    .main { flex: 0 0 100%; width: 100%; }
    .side { flex: 0 0 100%; width: 100%; }
  }


  /********** set up break point **********/

  .main-wrapper, .header { max-width: 1140px; }
  @media (max-width: 1199px) { .main-wrapper, .header { max-width: 960px; } }
  @media (max-width: 991px)  { .main-wrapper, .header { max-width: 720px; } }
  @media (max-width: 767px)  { .main-wrapper, .header { max-width: 540px; } }
  @media (max-width: 575px)  { .main-wrapper, .header { max-width: none; } }
  
  /* size the element who has breakpoint limitation */
  /* .header, .main-wrapper, .main { width: 100vw; } */
  /* .header-wrapper, .footer { width: 100vw; } */
  .header-wrapper, .footer { width: 100vw;}
  .main-wrapper { width: calc(100vw - 2 * var(--len-3));}



  .sidebar {
    order: 2;
    flex: 1 0 30%; /* This will make the sidebar take up 30% of the width */
    background-color: #f5f5f5; /* Change this to match your site's color scheme */
    padding: 1em; /* Add some padding around the sidebar content */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Optional: add a shadow for a 3D effect */
    position: sticky; /* Make the sidebar sticky */
    top: 0; /* Align the sidebar to the top of the viewport */
  }
  
  /* Style the sidebar links */
  .sidebar a {
    color: #333; /* Change this to match your site's color scheme */
    text-decoration: none;
    margin: 0.5em 0;
  }
  
  .sidebar h2 {
    font-size: 1.25em;
    margin-top: 0;
    text-decoration: dashed;
    text-align: center;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 0.5em;
  }
  
  
  /* Style the sidebar links on hover */
  .sidebar a:hover {
    color: #007BFF; /* Change this to match your site's color scheme */
  }
  
  /* Make the sidebar stack below the main content on small screens */
  @media (max-width: 768px) {
    main, .sidebar {
        flex: 1 0 100%;
    }
    .sidebar {
        position: static; /* Disable sticky sidebar on small screens */
    }
  }